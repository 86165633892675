import React from 'react';

class GetMarkData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        receiveddata: {p: " "},
        coin: this.props.coin.toLowerCase()
    };

  }
  async componentDidMount(prevState){
    var conn = new WebSocket("wss://fstream.binance.com/ws/" + this.state.coin + "@markPrice@1s")

    conn.onopen = function(evt) {

  }
  conn.onmessage = (evt) =>{
    var data = JSON.parse(evt.data)
      this.props.sendData(data);
      this.setState({receiveddata: data});
    };
  conn.onerror = (evt) =>{
      console.error('an error occurred', evt.data);
      this.setState({receiveddata: evt.data})

  };

}

  render() {
    return (
        <React.Fragment>
      </React.Fragment>
    );
  }
}

export default GetMarkData;