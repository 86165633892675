import React from 'react';
import GetMarkData from './getMarkData.js';
import GetApiData from './getApiData.js';
import GetWsData from './getWsData.js';
import GetAccountData from './getAccountData.js';

class Position extends React.Component {

  constructor(props) {
    super(props);
    this.getMarketPriceData = this.getMarketPriceData.bind(this);
    this.getAccountData = this.getAccountData.bind(this);
    this.getWsData = this.getWsData.bind(this);
    this.getData = this.getData.bind(this);

    this.state = {
      apiData: this.props.apiData,
      render: false, //Set render state to false
      leverage: "",
      long: {
        coin: ""
      },
      longedit:{
        markPrice: ""
      }
 
    };

  }
  async componentDidMount() {
    setTimeout(function() { //Start the timer
      this.setState({render: true}) //After 1 second, set render to true
    }.bind(this), 5000)

}

getMarketPriceData(val){
  this.setState({marketprice: val.p});

  if(this.state.LONG){
    var pnllong = (val.p - this.state.long.entryPrice) * this.state.long.positionAmount;
    this.setState({longedit: {pnl: pnllong.toFixed(2), marketprice: val.p}});
  }
  if(this.state.SHORT){
    var pnlshort = (val.p - this.state.short.entryPrice) * this.state.short.positionAmount;
    this.setState({shortedit: {pnl: pnlshort.toFixed(2), marketprice: val.p}});
  }  
  if(this.state.ONEWAY){
    var pnloneway = (val.p - this.state.oneway.entryPrice) * this.state.oneway.positionAmount;
    this.setState({onewayedit: {pnl: pnloneway.toFixed(2), marketprice: val.p}});
  }


}

getData(val){
  // this.setState({receiveddataPosition: {a: {P: [{s: val.symbol,ep: val.entryPrice, up: val.unRealizedProfit}] }, o: {s: " "}}, pnl:val.unRealizedProfit, leverage: val.leverage, coin: val.symbol});
  this.setState({coin: val.symbol, entryPrice: val.entryPrice, pnl: val.unRealizedProfit, leverage: val.leverage, positionAmount: val.positionAmt, marketprice: val.markPrice, type: val.positionSide})

  if(val.positionSide == "LONG"){
    this.setState({long: {coin: val.symbol, entryPrice: val.entryPrice, leverage: val.leverage, positionAmount: val.positionAmt, marketprice: val.markPrice, type: "L", Liquidprice: val.liquidationPrice}})
    this.setState({longedit: {pnl: Number.parseFloat(val.unRealizedProfit).toFixed(2), marketprice: val.markPrice}})
    this.setState({LONG: true}) 
  }else if(val.positionSide == "SHORT"){
    this.setState({short: {coin: val.symbol, entryPrice: val.entryPrice, pnl: val.unRealizedProfit, leverage: val.leverage, positionAmount: val.positionAmt, marketprice: val.markPrice, type: "S"}})
    this.setState({shortedit: {pnl: Number.parseFloat(val.unRealizedProfit).toFixed(2), marketprice: val.markPrice}})
    this.setState({SHORT: true}) 
  }else if(val.positionSide == "ONEWAY"){
    this.setState({oneway: {coin: val.symbol, entryPrice: val.entryPrice, pnl: val.unRealizedProfit, leverage: val.leverage, positionAmount: val.positionAmt, marketprice: val.markPrice, type: "O"}})
    this.setState({onewayedit: {pnl: Number.parseFloat(val.unRealizedProfit).toFixed(2), marketprice: val.markPrice}})
    this.setState({ONEWAY: true}) 
  }

}
getWsData(val){
  this.setState({wsData: val})
}
getAccountData(val){
  
}
  render() {
    let renderContainer = false //By default don't render anything
    if(this.state.render) { //If this.state.render == true, which is set to true by the timer.
      renderContainer = <GetMarkData coin = {this.state.coin} sendData={this.getMarketPriceData} />
    }
    let longContainer = false
    if(this.state.LONG){
      longContainer = <React.Fragment>
        <table class=" col-sm-3 table table-striped table-dark float-left border borderthickness bordercolor">
                <thead>
                  <tr>
                    <th scope="col">Entryprice</th>
                    <th scope="col">PNL</th>
                    <th scope="col">Lp</th>
                    <th scope="col">Amount</th>
                    <th scope="col">MR</th>
                    <th scope="col">T</th>

                  </tr>
                </thead>
                <tbody>
                <tr>
                  <th>{this.state.long.entryPrice}</th>
                  <th>{this.state.longedit.pnl}</th>
                  <th>{this.state.long.Liquidprice}</th>
                  <th>{this.state.long.positionAmount}</th>
                  <th></th>
                  <th>{this.state.long.type}</th>

                </tr>
                </tbody>
              </table>



      </React.Fragment>
    }
    let shortContainer = false
    if(this.state.SHORT){
      shortContainer = <React.Fragment>
                <table class="col-sm-3 table table-striped table-dark float-left ml-4 border border-danger borderthickness">
                <thead>
                  <tr>
                    <th scope="col">Entryprice</th>
                    <th scope="col">PNL</th>
                    <th scope="col">Lp</th>
                    <th scope="col">Amount</th>
                    <th scope="col">MR</th>
                    <th scope="col">T</th>

                  </tr>
                </thead>
                <tbody>
                  <tr> 
                    <th>{this.state.short.entryPrice}</th>
                    <th>{this.state.shortedit.pnl}</th>
                    <th>{this.state.long.Liquidprice}</th>
                    <th>{this.state.short.positionAmount}</th>
                    <th></th>
                    <th>{this.state.short.type}</th>
                  </tr>
                </tbody>
              </table>

      </React.Fragment>
    }
    let onewayContainer = false
    if(this.state.ONEWAY){
      onewayContainer = <React.Fragment>
                        <table class="col-sm-3 table table-striped table-dark float-left ml-4 border border-primary borderthickness">
                <thead>
                  <tr>
                    <th scope="col">Entryprice</th>
                    <th scope="col">PNL</th>
                    <th scope="col">Lp</th>
                    <th scope="col">Amount</th>
                    <th scope="col">MR</th>
                    <th scope="col">T</th>

                  </tr>
                </thead>
                <tbody>
                  <tr> 
                    <th>{this.state.oneway.entryPrice}</th>
                    <th>{this.state.onewayedit.pnl}</th>
                    <th>{this.state.long.Liquidprice}</th>
                    <th>{this.state.oneway.positionAmount}</th>
                    <th></th>
                    <th>{this.state.oneway.type}</th>
                  </tr>
                </tbody>
              </table>

      </React.Fragment>
    }
    let empty = false
    if(this.state.SHORT || this.state.LONG || this.state.ONEWAY){
      empty = <React.Fragment>
          <div class="col-sm-12 float-left mt-2">
          <div class="card h-520 text-white bg-dark ">
          <div class="card-header text-center"><h4>Bot: {this.state.apiData.bot} {this.state.coin}</h4></div>
            <div class="card-body">
              <h5 class="card-title">Marketprice: {this.state.marketprice}</h5>
              

                  {longContainer} 
                  {shortContainer} 
                  {onewayContainer} 
          </div>
          </div>
          </div>
      </React.Fragment>
    }


    return (
      <React.Fragment>
      <GetAccountData apiData = {this.state.apiData} sendData={this.getAccountData} />
      <GetApiData apiData = {this.state.apiData} sendData={this.getData} />
      <GetWsData apiData = {this.state.apiData} sendData={this.getWsData}/>
      {renderContainer} 
      {empty}
  </React.Fragment>
);
  }
}

export default Position;






