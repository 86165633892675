  import React from 'react';
  import Main from './components/main.js'


  class App extends React.Component {
    

    render() {
      return (
        <React.Fragment>

        <Main />
        </React.Fragment>

      );
    }
  }
 
  export default App;