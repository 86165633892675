import React from 'react';
const crypto = require('crypto');


class GetAccountData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      apiData: this.props.apiData,
      bool: true
    };


  }
   signature(query_string, apiSecret) {
    return crypto
        .createHmac('sha256', apiSecret)
        .update(query_string)
        .digest('hex');
  }

  async componentDidMount() {
    var time = Date.now();
    var secret = this.state.apiData.apiSecret
    var key = String(this.state.apiData.apiKey)
    var query_string = "recvWindow=5000000&timestamp=" + time;
    var signature = this.signature(query_string, secret)
    fetch("https://fapi.binance.com/fapi/v2/account?" + query_string + "&signature=" + signature, {
      "method": "GET",
      "headers": {
        "X-MBX-APIKEY": key
      }
    })
    .then(response => response.json())
    .then(response => {
      response.forEach((data) => {
        console.info(data)

      })
    })
    .catch(err => { console.log(err); 
    })
}

  render() {
    return (
        <React.Fragment>
      </React.Fragment>
    );
  }
}

export default GetAccountData;