import React from 'react';
const crypto = require('crypto');


class GetApiData extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      apiData: this.props.apiData,
      bool: true
    };


  }
   signature(query_string, apiSecret) {
    return crypto
        .createHmac('sha256', apiSecret)
        .update(query_string)
        .digest('hex');
  }

  async componentDidMount() {
    var time = Date.now();
    var secret = this.state.apiData.apiSecret
    var key = String(this.state.apiData.apiKey)
    var query_string = "recvWindow=5000000&timestamp=" + time;
    var signature = this.signature(query_string, secret)
    fetch("https://fapi.binance.com/fapi/v1/positionRisk?" + query_string + "&signature=" + signature, {
      "method": "GET",
      "headers": {
        "X-MBX-APIKEY": key
      }
    })
    .then(response => response.json())
    .then(response => {
      response.forEach((data) => {
        if(data["positionAmt"] != 0){
          console.info(data)

          this.props.sendData(data);
          this.setState({bool: false})
        }

      })
    })
    .catch(err => { console.log(err); 
    })
    if (this.state.bool){
      var data = {symbol: "Geen Positie"}
      this.props.sendData(data);

    }
}

  render() {
    return (
        <React.Fragment>
      </React.Fragment>
    );
  }
}

export default GetApiData;