import React from 'react';

class Marketprice extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        receiveddata: {p: " "},
        data: this.props.apiData
    };


  }
  async componentDidMount() {
    var conn = new WebSocket("wss://fstream.binance.com/ws/" + this.state.data.listenKey)


  conn.onmessage = (evt) =>{
      var data = JSON.parse(evt.data)
      if(data.e === "ORDER_TRADE_UPDATE"){
        this.setState({receiveddataOrder: JSON.parse(evt.data)})

      }
      if(data.e === "ACCOUNT_UPDATE"){
        this.setState({receiveddataPosition: JSON.parse(evt.data)})
        console.info('received data', JSON.parse(evt.data));
        }
      if(evt.data.e === "ACCOUNT_CONFIG_UPDATE"){
        this.setState({leverage: JSON.parse(evt.data.l)})
      }
    };
  conn.onerror = (evt) =>{
      console.error('an error occurred', evt.data);
      this.setState({receiveddata: evt.data});

  };

}

  render() {
    return (
        <React.Fragment>
      </React.Fragment>
    );
  }
}

export default Marketprice;