import React from 'react';
import Position from './position.js';

class Main extends React.Component {

    constructor(props){
        super(props);
        this.state = {
             data: [
              {bot: '1', listenKey: "ZV0nENAwvpFNQflolA3JVdELsa5ZkQZbdERmZ3KDka2ZIQ7et3DXCUy6REVHGeik", apiKey: "2kEB7roAnPjg7GxBUVBfPS6DVQ50rf38Pmn7sR7yqcyE3TcYg5Q90X4Wy0TuioQ8", apiSecret: "okr31IL8dl8vNBbm0iz1Kau6XsAtxcp3S9TGWWi4l10K8YMcSkntz5Jiwg5qOc96"},
              {bot: '2', listenKey: "vewqeooE5jpKNA79geyr4rE8bHBtQhOi9Wsohpd4pNrBhDBWdtjq6JbRUKNgmEuc", apiKey: "sbPrYz0ybJ78FilR3ZyP4DTvXOekJaqX9m0oo4uIXQYRUEc9Z1MJMiJaBJMgdwRD", apiSecret: "4654IoxiL1ioJoAgb3FosPQCFdMGCRTfxFwp29N1r28UAulfVOavt8yce7xcdNkl"},
              {bot: '3', listenKey: "SctyT8qedttUKsfOU9mm0gyfoFPRXU2C92tmNDr1ar2mdQ5H88nE4zQy9dEulxbv", apiKey: "x2F4PbMWlnHqFKoxdtjjot3a36M3xsLm3fHaqtvtyhMVJaARjSWGtp70D3P3rNLO", apiSecret: "JNabTV9byKIoJqwLYmPpICyPIXQ6jLmZM1pOgs8hHZYQTt56uG5rgGELe8nO2zfb"},
              {bot: '4', listenKey: "mHTppJy8hHYwBx8K4VlRVE2bgxpq16fQF3xR49QSZswFHhkkRpNcj4bGipwnU2bh", apiKey: "FBSoHtbRvWHkR1qqP0Y8FchFMqhgLrAJUEMHeijnjODBxkdARI8XPnN8xxEHldc9", apiSecret: "IKoICFw5lyOF9jBbz0TGKVQ8shZar77StvyVItIb5u72hTOiVi06wlsWG1HBIY1W"},
              {bot: '5', listenKey: "Xm9htna5hU7f3uqhZdmgaNH4dMX3dTsznikh1b0pHacSfz43BOsRlvsgROVBYCL6", apiKey: "5VyrbEktrYAu94bVCTbPtgjrwutt4BgKih2Nc12VXXs6MtBB8nmu5o7caKhJCwHG", apiSecret: "coRNesH1heTsMSXqe3HzXdqnQejJ5TbVwdL96AgO8pUQFrhHN8VBiYYeDCmgTqMG"},
              {bot: '6', listenKey: "0YiqoBaFlgzkgolJYTBoopnW0yhpFtrHd0XdFuY8Ql6RHGeJ5l1buGFH5hHj3O3l", apiKey: "8xCxeuCDegrKBR0GN4ntRSHxbfXDSt8GTDKrpBZyugAzu4V7UkA9uncP3qe9gkrY", apiSecret: "w4qRwaxN6F8hR33dbYggbQTUccOXxi1N78WEuCT9g3qdBvK9DU02wHGwSjzJ8UDc"},
              {bot: '7', listenKey: "DXtub4H9qME42LSmwNs22eyYsEFCMZK7Jfo54HA4jqAgL9wXnbvGzovajtgjBrGS", apiKey: "V8DajxiDqvbINaKakjrmsRqDZsJ2o0cz8JqtgMMVsDSTiB5y4fQUGWdpeF7L2TWs", apiSecret: "3QsvCgGCnmEflj89fklPX0f46Jh0Sb6kEibHR1bKiRVPhpXY5LtBrJbJ1z7HIbB9"},
              {bot: '8', listenKey: "yhu2lEyXTITk4S4ym3nuqZn8IHkTFFomjCxePgPNuDJzoQahb0vU417SXgQbhZB1", apiKey: "U9xz4SmHxBIJxr5khhK5PlqOie6aoZjvvTNqdhgB0lhhJ1dvfCnoHBkJZJjMvsZd", apiSecret: "OidKQVqxHWfcImosztvvc3exqLKDIPhI4fuRWno3ko18ym7rZ0hu11WueW9wqY7f"},
              {bot: '9', listenKey: "gNoKLPNoOeFimMgJGoBqqwCsBw3O3vuJrRdRpO338vKffwMrixF03xDh7D0vVEo5", apiKey: "0Z23hcj8ojC00GpPFnDvrL2ujD0E20uElbCO8qlqVtPFs8hnGzQxW7IZbnX4Yb1Z", apiSecret: "ckZoD7IAG6UvMxQCaf1AF9xCezZ6qAmAux8XHUhT61zomsdUDI6WdWsCCjnKFZrp"},
              {bot: '10', listenKey: "wxS9flp3Goelqo1iYeueHOzfrJDtQgwBqGymkB2noxjwHxXl2Xtqpt6EQsoYGGIl", apiKey: "kDQdzURvHQcDYFx3tbLq00xru1l2v1XmAwbWpbmpCqZKAzMXrG1i4sOgoZpjON9Y", apiSecret: "PSZuhF2BUGNSZRljkuJwOpCk33FyR3Sec1MtluGG1kOtshG3lUFjpJS392om6AGH"},
              {bot: '11', listenKey: "ZsOxgO68dE7coHI12iDLpCPOPzlprDCOh4HhFdqWh8RNTDyUkYejF5dtA50N9ZKc", apiKey: "yuLFBDI7CP3MbO2LJaVCCFRsLLFkvGeZ7HuZFRURQVMzWwrK77qy3u5YM2JYUqkK", apiSecret: "VOs2XGePA1XwxNiP0UDwAma8e1IzcS3ppm6LZU8q3ewQX9z7j3WzCZoSLAgvjoTa"},
              {bot: '12', listenKey: "yRrTy0TXyHUht1Mqz8U3bvEXO4ndxUSUtPsaIRw1inoKaRLMoU35yFfqyXqhPpGN", apiKey: "F9eXsCRljs4VU8xEczJ5iRl49K4UUdu4SA8zahXnwbEvoQPbHcmWpoiZ9A0QPybt", apiSecret: "tMaJWiMz2EzSE2gMBH2qnTuyvHfgO40ppgTGnzPs81FMDY8dxqukL8AzUtwJrJo6"},
              {bot: '13', listenKey: "lJun4pBqSN3dg87XbXh7tCJQ1a5HAzBd18rJ9ojVU8SJwppjaf6fUblq1NLHdEWq", apiKey: "hO7s8ELJjEe9zXWh2XeuMQg6gIo6eqMK7OfZTx9j6LmjJQFuWmvWYMHLhNDURj6U", apiSecret: "oUt2uQ9ldpJiy02VNRBPl4z2Mg6NiUGPFgcH9wZdgcnDRTJ5T6IBpPrJAhqefIBr"},
              {bot: '14', listenKey: "R3Y1EhrK1bvnvWKZAQY1GVANo2csZktq9mpsGJvj2eZHqswGzGZk6df2hxRK5T8N", apiKey: "nrJAaAXyEeXkE3HPGtPI5ASCFOVInF5JUrBvkxx5QTPhCQGSpswKaII0MYxslaYc", apiSecret: "00XEms8iPWogkl82FKJFFchixRm5Ta1A3DZnYT1OgVASDUHQfFSKGyWJyu3RqRcs"},
              {bot: '15', listenKey: "fkeLSQp0pCbMU9PwOyyaWaK12GAeUxI5YWjvXxstRPmM4Bw8dJoUZwhqKmq4kyIK", apiKey: "8KGGh3IW9abZVvPX1HiBxeqPDbhLMqHtCurHZW5eFkjjRXZzJsk4aooHlVPkO7o8", apiSecret: "5TbLOK9fgs9yejuJruGdhCYu5aMwbtOSN5o7sveCxZ0KmQvdX697QV8Bb6DphOSO"},
              {bot: '16', listenKey: "oCcO1etcnTotNaLLXhQojzv13ZN8CP23HLQOAnymJjeFUhoUrmBBUHViuBkg1V59", apiKey: "m2ssjOp1ycsO5NuBwFjfkPeAKFRaOZPj3RtNX4WERARBSZV9RfvK8wtXfbMqPFSF", apiSecret: "a4wKQ8j18fpNJaEI5nEUWGZAOI3dGDU6cI5A1uQ0nMZYPdap6bt2UMSofTvAcQQf"},
              {bot: '17', listenKey: "OueiRy7IjyHFBrjQU6eBy2NKUhTqMtmALHkL8HThqkZ6zWppA8JeK9zCw0uQz2ez", apiKey: "IxxPJ5GMpDjVrmntEM4Wq1kFKxfJaxSOOoC6ASiF5Myhe0jT5LvgvGzt5wZhzWyj", apiSecret: "6IgfuTd85CZxt0aROOyucvAGz1jmriDtLfzkO4BP1vK8PSyBp26yc3AB1v8DhiMj"},
              {bot: '18', listenKey: "5RKMm72zzyN4LHfsGL4Jin9fGYCKxNHMJcqP3CpWZOn060QfVVktJi1R4AlH7QVR", apiKey: "UL1tCamCJdHCx6p7FcDJR2P2aQewAIYyjEy0vJsM2dmhNUOVOORYaJUUZyQZiYh6", apiSecret: "UADiBADPMqwKVhRUK4peBgaZY34v8BSarttCO0zJoZFiljw8PkaK0gwJqre9o2fO"},
              {bot: '19', listenKey: "Ubb1nGO8Di2fQ81GeyThDRAgk5f5ec1Or6mO7aGmYSKEQDX97UThw1p5I37DdML3", apiKey: "5RCXovMMP9dnbaUyTj7FQuQvfv2b6MvevqZpLJxZ9MLnCgRzK94bUr6ZY3J6AHrJ", apiSecret: "3NBJ7sjtki7XBkWab5QrbP2y779jBWbWLhjwWB7qTL6se6reG2fRWGfEw6QBkOMC"},
              {bot: '20', listenKey: "vx95hcftgJLnKvJRo8vsV7pnto388eYmnVKdu4i93rMQz6KNly7utmogJZf7M98L", apiKey: "7ufc69TyHh9QgcwC9ybslTQDT75KYrF3YEIoXgwIvFnzfMMwz30csVmkQbDzyrJy", apiSecret: "XTBb0qSuYsFmCa02bFFozjsL32qenrU8q8JLChKdTCi572PcYB9ZOzVzPD51XJpm"},

        ]
      }
    }
    positions = () => {
      var arr = [];
      for(var i = 0; i< 20;i++){
        arr.push(<Position apiData  = {this.state.data[i]}/>);
        if(i % 3 === 0){
 

          console.info("abc")
        }
      }
      return arr
    }

    
    render() {
      return (
        <React.Fragment>
          <Position apiData  = {this.state.data[0]}/>
          <Position apiData  = {this.state.data[1]}/>
          <Position apiData  = {this.state.data[2]}/>

          <Position apiData  = {this.state.data[3]}/>
          <Position apiData  = {this.state.data[4]}/>
          <Position apiData  = {this.state.data[5]}/>

          <Position apiData  = {this.state.data[6]}/>
          <Position apiData  = {this.state.data[7]}/>
          <Position apiData  = {this.state.data[8]}/>

          <Position apiData  = {this.state.data[9]}/>
          <Position apiData  = {this.state.data[10]}/>
          <Position apiData  = {this.state.data[11]}/>

          <Position apiData  = {this.state.data[12]}/>
          <Position apiData  = {this.state.data[13]}/>
          <Position apiData  = {this.state.data[14]}/>

          <Position apiData  = {this.state.data[15]}/>
          <Position apiData  = {this.state.data[16]}/>
          <Position apiData  = {this.state.data[17]}/>

          <Position apiData  = {this.state.data[18]}/>
          <Position apiData  = {this.state.data[19]}/>
        </React.Fragment>

      )
  }

}

export default Main;


{/* {this.positions()} */}